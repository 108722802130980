
import Button from '~/components/atoms/Button'

export default {
    name: 'WefunderBanner',

    components: {
      Button
    },

    props: {
    },

    data () {
      return {
      }
    },

    computed: {

    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {

    },

    methods: {
      goTo() {
        window.open('https://wefunder.com/sportblx', '_blank')
      }
    }
  }


  export default {
    name: 'Button',

    props: {
      color: {
        type: String,
        required: false,
        default: 'primary'
      },
      outline: {
        type: Boolean,
        default: false
      },
      block: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
      depressed: {
        type: Boolean,
        required: false,
        default: false
      },
      height: {
        type: String,
        required: false,
        default: undefined
      }
    },

    data () {
      return {
        viewWidth: null
      }
    },

    mounted() {
      this.viewWidth = this.width
      if (!this.viewWidth) {
        const _width = this.$el.offsetWidth
        if (_width > 250) {
          this.viewWidth = null
        } else {
          this.viewWidth = `${_width}px`
        }
      }
    },

    methods: {
      onClick() {
        this.$emit('click')
      },
      onClickStop() {
        this.$emit('click.stop')
      }
    }
  }

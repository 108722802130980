
export default {
  data () {
    return {
      on: false,
      message: '',
      color: '',
      icon: ''
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notification/notify') {
        this.message = state.notification.content
        this.color = state.notification.color
        this.icon = this.color === 'success' ? 'mdi-check-circle-outline'
        : this.color === 'error' ? 'mdi-close-circle-outline'
        : this.color === 'info' ? 'mdi-information-outline'
        : ''
        this.on = true
      }
    })
  }
}


export default {
    name: 'TitleSubTitle',

    components: {
    },

    props: {
      title: {
        type: [String, Number, Boolean],
        required: true,
      },
      subtitle: {
        type: [String, Number, Boolean],
        required: true,
      },
      noBreak: {
        type: Boolean,
        required: false,
        default: false
      },
      sFontSize: {
        type: String,
        required: false,
        default: null,
      },
      sFontColor: {
        type: String,
        required: false,
        default: null,
      },
      sFontFamily: {
        type: String,
        required: false,
        default: null,
      },
      fontSize: {
        type: String,
        required: false,
        default: null,
      },
      fontColor: {
        type: String,
        required: false,
        default: null,
      },
      fontFamily: {
        type: String,
        required: false,
        default: null,
      },
      titleTag: {
        type: String,
        required: false,
        default: 'data',
      },
      subTitleTag: {
        type: String,
        required: false,
        default: 'data',
      },
      titleClass: {
        type: String,
        required: false,
        default: '',
      },
      subClass: {
        type: String,
        required: false,
        default: '',
      },
      customStyle: {
        type: Object,
        required: false,
        default: () => {}
      }
    },

    data () {
      return {
      }
    },

    computed: {
      titleStyle () {
        const fontSize = this.fontSize ? `font-size: ${this.fontSize}; ` : ' '
        const fontColor = this.fontColor ? `color: ${this.fontColor}; ` : ' '
        const fontFamily = this.fontFamily ? `font-family: ${this.fontFamily}; ` : ' '
        const customStyle = (this.customStyle && this.customStyle.title) || ''
        return `${fontSize}${fontColor}${fontFamily} ${customStyle}`
      },
      subStyle () {
        const sFontSize = this.sFontSize ? this.sFontSize : this.fontSize
        const sFontColor = this.sFontColor ? this.sFontColor : this.fontColor
        const sFontFamily = this.sFontFamily ? this.sFontFamily : this.fontFamily

        const fontSize = sFontSize ? `font-size: ${sFontSize}; ` : ' '
        const fontColor = sFontColor ? `color: ${sFontColor}; ` : ' '
        const fontFamily = sFontFamily ? `font-family: ${sFontFamily}; ` : ' '
        const customStyle = (this.customStyle && this.customStyle.subtitle) || ''
        return `${fontSize}${fontColor}${fontFamily} ${customStyle}`
      }
    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {

    },

    methods: {

    }
  }

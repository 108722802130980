
export default {
  name: 'MenuButton',
  props: {
    menuOpen: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bg() {
      return `background-color: ${this.isHome ? '#fff' : '#000'};`
    },
    color() {
      return `color: ${this.isHome ? '#fff' : '#000'};`
    }
  },
  methods: {
    toggleMenu() {
      this.$emit('menu', this.menuOpen)
    }
  }
}


  export default {
    name: 'HowItWorksModal',
    props: {
      value: {
        type: Boolean,
        required: true
      },
      cardLink: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        title: '',
        videoLink: '',
        open: this.value,
      }
    },
    watch: {
      open (newVal) {
        if (!newVal) {
          this.$emit('close')
        }
      },
      value (newVal, oldVal) {
        this.open = newVal
      }
    },
    created () {
      if (this.cardLink[0]) {
        if (this.cardLink[0].url && this.cardLink[0].url.input) {
          this.videoLink = this.cardLink[0].url.input
        }

        if (this.cardLink[0].title && this.cardLink[0].title.input) {
          this.title = this.cardLink[0].title.input
        }
      }
    }
  }


import { mapState, mapGetters } from 'vuex'
export default {
    name: 'Banner',

    props: {
      noBreak: {
        type: Boolean,
        required: false,
        default: false
      },
      sFontSize: {
        type: String,
        required: false,
        default: null,
      },
      sFontColor: {
        type: String,
        required: false,
        default: null,
      },
      sFontFamily: {
        type: String,
        required: false,
        default: null,
      },
      fontSize: {
        type: String,
        required: false,
        default: null,
      },
      fontColor: {
        type: String,
        required: false,
        default: null,
      },
      fontFamily: {
        type: String,
        required: false,
        default: null,
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      titleTag: {
        type: String,
        required: false,
        default: 'data',
      },
      subTitleTag: {
        type: String,
        required: false,
        default: 'data',
      },
      titleClass: {
        type: String,
        required: false,
        default: '',
      },
      subClass: {
        type: String,
        required: false,
        default: '',
      },
      customStyle: {
        type: Object,
        required: false,
        default: () => {}
      }
    },

    data () {
      return {
        signUpUrl: this.$config.SIGNUP_URL,
        form: {
          email: '',
          loading: false
        }
      }
    },

    computed: {
      titleStyle () {
        const fontSize = this.fontSize ? `font-size: ${this.fontSize}; ` : ' '
        const fontColor = this.fontColor ? `color: ${this.fontColor}; ` : ' '
        const fontFamily = this.fontFamily ? `font-family: ${this.fontFamily}; ` : ' '
        const customStyle = (this.customStyle && this.customStyle.title) || ''
        return `${fontSize}${fontColor}${fontFamily} ${customStyle}`
      },
      subStyle () {
        const sFontSize = this.sFontSize ? this.sFontSize : this.fontSize
        const sFontColor = this.sFontColor ? this.sFontColor : this.fontColor
        const sFontFamily = this.sFontFamily ? this.sFontFamily : this.fontFamily

        const fontSize = sFontSize ? `font-size: ${sFontSize}; ` : ' '
        const fontColor = sFontColor ? `color: ${sFontColor}; ` : ' '
        const fontFamily = sFontFamily ? `font-family: ${sFontFamily}; ` : ' '
        const customStyle = (this.customStyle && this.customStyle.subtitle) || ''
        return `${fontSize}${fontColor}${fontFamily} ${customStyle}`
      },
      ...mapState('appSettings', [
        'textHomepageTitle',
        'textHomepageSignup',
      ]),
      ...mapGetters('newProviders', ['presignedUpResult'])
    },

    watch: {
      presignedUpResult: {
        handler() {
          if (this.presignedUpResult?.result?.data) {
            setTimeout(() => {
              this.form.loading = false
              window.location.href = `${this?.signUpUrl}&email=${this.form.email}`
            }, 1500)
          }
        }
      }
    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    methods: {
      async submit() {
        if (!this?.form?.email) {
          return
        }
        this.form.loading = true
        await this.$store.dispatch('newProviders/savePresignupEmail', this.form?.email)
      }
    }
  }

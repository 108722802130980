
export default {
    name: 'FooterLinks',

    components: {
    },

    props: {
      // eslint-disable-next-line vue/require-default-prop
      title: {
        type: Object,
        required: false
      },
      // eslint-disable-next-line vue/require-default-prop
      links: {
        type: Array,
        required: false
      }
    },

    data () {
      return {
      }
    },

    computed: {

    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {

    },

    methods: {

    }
  }


export default {
    name: 'AssetCard',

    components: {

    },

    props: {
      title: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      descriptionFontSize: {
        type: String,
        required: false,
        default: '14px'
      },
      footerFontSize: {
        type: String,
        required: false,
        default: '14px'
      },
      footerFontWeight: {
        type: String,
        required: false,
        default: ''
      },
      footerColor: {
        type: String,
        required: false,
        default: '#484E58'
      },
      coverImage: {
        type: String,
        required: true,
      },
      isLargeImage: {
        type: Boolean,
        required: false,
        default: false
      },
      hasSpace: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    data () {
      return {
        imageError: false,
      }
    },

    computed: {
      imageClasses() {
        const defaultClass = 'asset-card-image'

        if (this.isLargeImage) {
          return defaultClass + ' asset-card-image-large'
        }

        return defaultClass + ' asset-card-image-small'
      },
      footerStyle () {
        let style = ''
        style += 'font-size: ' + this.footerFontSize
        style += ';color: ' + this.footerColor

        if (this.footerFontWeight) {
          style += ';font-weight: ' + this.footerFontWeight
        }

        return style
      }
    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {

    },

    methods: {
      handleImageError () {
        this.imageError = true
      }
    }
  }


export default {
    name: 'SocialButtons',

    components: {
    },

    props: {
      links: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        imageError: false,
      }
    },

    computed: {

    },

    beforeCreate () {

    },

    created () {

    },

    beforeMount () {

    },

    mounted () {

    },

    methods: {
      handleImageError () {
        this.imageError = true
      }
    }
  }
